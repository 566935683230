.authform {
    width: 25rem;
    max-width: 80%;
    margin: 6rem auto;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.form-control label, .form-control input {

    display: flex;
    align-items: center;
    justify-content: center;


}

.loginButton {
    width: 150px;
    padding: 15px 25px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
}

/* 
.left, .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; */
/* } */

/* .loginTitle {
    position: absolute;
    top: 150px;
    color: lightgray
} */

.link {
    color: inherit;
    text-decoration: none;
}


.wrapper {
    width: 60%;
    height: 75%;
    -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
    display: flex;
    align-items: center;
    border-radius: 20px;
}


.left, .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.or {
    border: 2px solid lightgray;
    border-radius: 50%;
    padding: 10px;
    color: gray;
    background-color: white;
    font-weight: bold;
}

.center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.line {
    width: 0.5px;
    height: 70%;
    background-color: lightgray;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
}

.center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.google {
    background-color: #df4930;
}

.facebook {
    background-color: #507cc0;
}

.twitter {
    background-color: blue;
}

.form-control label {

    margin-bottom: .50rem;
}

.loginTitle {
    position: absolute;
    top: 150px;
    color: lightgray
}

.form-control input {

    width: 200px;
    padding: 15px 20px;
    margin-bottom: 20px;
}


.form-actions button {
    width: 200px;

    background-color: red;
    font: inherit;
    border: 1px solid red;
    border-radius: 5px;
    /* padding: 0.25rem 1rem; */
    padding: 15px 20px;

    /* margin-left: 5rem; */
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 26);
    color: white;
    cursor: pointer;

}

.form-actions button:hover, .form-actions button:active {

    color: black;




}