.menu {
    color: aquamarine;
    max-width: max-content;
    font-weight: bolder;
    display: flex;
}



.loginbar {
    height: 30px;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-around;

}

.list {
    display: flex;
    align-items: center;
    margin-right: 10px;
    list-style: none;
    text-justify: auto;
}

.listitem {

    margin: 20px;
    font-weight: 500;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-shadow: 0rem2;
    cursor: pointer;
}

.avatar {

    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 1rem;
}

.Navbar {
    height: 30px;
    background-color: red;
    color: white;
    display: flex;
    list-style: none;
    justify-content: space-around;
    cursor: pointer;
}


.link {
    display: flex;
    align-items: center;
    margin-right: 10px;
    list-style: none;
    text-justify: auto;
    font-weight: 500;

}